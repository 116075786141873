<template>
  <div>
    <v-card>
      <v-card-text class="d-flex">
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="fetchData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>

        <v-spacer />

        <generate-dialog
          :distributor-id="filters.distributorId"
          @generated="fetchData"
        />

        <v-btn depressed color="success" @click="exportVoucher">
          <v-icon class="prefix-icon">{{ icons.mdiDatabaseExportOutline }}</v-icon>
          {{$t('common.list.export')}}
        </v-btn>
      </v-card-text>

      <!-- Table -->
      <voucher-table
        ref="tableRef"
        :loading="loading"
        :resources="vouchers"
        :resources-total="vouchersTotal"
        @resources-updated="fetchData"
        @options-updated="onOptionsUpdated"
      />


    </v-card>
  </div>
</template>

<script>
import { mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiRefresh } from '@mdi/js'

import { t } from '@/plugins/i18n'
import dayjs from 'dayjs'

import { useOldDataFetch, useOldTableList } from '@/composables'
import { ref } from '@vue/composition-api'
import VoucherTable from '../components/VoucherTable.vue'
import GenerateDialog from '@/modules/rewardPoint/voucher/components/GenerateDialog'
import { voucherService, VoucherExportService } from '../services'

export default {
  components: {
    VoucherTable,
    GenerateDialog,
  },

  setup() {
    const { parseParam } = useOldDataFetch()
    const { loading, tableRef, mergeOptionToParams } = useOldTableList()

    const vouchers = ref([])
    const vouchersTotal = ref(0)

    const filters = ref({
      distributor_id: null,
      batch_id: null,
      redeemed: null,
    })

    const params = ref({})

    // const redeemStatus = ref([
    //   {
    //     title: 'Redeemed',
    //     value: 1,
    //   },
    //   {
    //     title: 'Not Yet Redeemed',
    //     value: 0,
    //   },
    // ])

    const fetchData = async () => {
      loading.value = true

      try {
        const { records, pagination } = await voucherService.index(parseParam(params.value))

        vouchers.value = records
        vouchersTotal.value = pagination.total
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const exportVoucher = async () => {
      await VoucherExportService.store(parseParam(filters.value))
    }

    const onFilterUpdated = value => {
      params.value = {
        ...params.value,
        ...value,
      }

      // Reset page
      params.value.page = 1
      tableRef.value.resetPage(1)

      fetchData()
    }

    const onOptionsUpdated = options => {
      params.value = mergeOptionToParams(options, params.value)

      fetchData()
    }

    return {
      t,
      dayjs,

      // Refs
      loading,
      tableRef,
      filters,
      vouchers,
      vouchersTotal,

      // functions
      fetchData,
      exportVoucher,
      onFilterUpdated,
      onOptionsUpdated,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiRefresh,
        mdiDatabaseExportOutline,
        mdiMenuUp,
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
