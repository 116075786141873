var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-card-text', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.fetchData
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiRefresh) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('common.list.refresh')) + " ")])], 1), _c('v-spacer'), _c('generate-dialog', {
    attrs: {
      "distributor-id": _vm.filters.distributorId
    },
    on: {
      "generated": _vm.fetchData
    }
  }), _c('v-btn', {
    attrs: {
      "depressed": "",
      "color": "success"
    },
    on: {
      "click": _vm.exportVoucher
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(_vm._s(_vm.icons.mdiDatabaseExportOutline))]), _vm._v(" " + _vm._s(_vm.$t('common.list.export')) + " ")], 1)], 1), _c('voucher-table', {
    ref: "tableRef",
    attrs: {
      "loading": _vm.loading,
      "resources": _vm.vouchers,
      "resources-total": _vm.vouchersTotal
    },
    on: {
      "resources-updated": _vm.fetchData,
      "options-updated": _vm.onOptionsUpdated
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }